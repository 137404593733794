import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getIdToken } from '../../../services/firebaseServices'; // Adjust the path to where firebaseServices is defined
import { Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar, CircularProgress, Container } from '@mui/material';

const GroupProfiles = () => {
    const { groupId } = useParams();
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchGroupMembers = async () => {
            try {
                const token = await getIdToken();
                const config = {
                    method: 'get',
                    url: `https://api.blueprintportal.net/groups/${groupId}/members`,
                    headers: { 'Authorization': `${token}` }
                };

                const response = await axios(config);
                setMembers(response.data || []);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching group members:', err);
                setError('Failed to load group members');
                setLoading(false);
            }
        };

        fetchGroupMembers();
    }, [groupId]);

    if (loading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <Typography color="error">{error}</Typography>
            </Container>
        );
    }

    return (
        <Container component="main" maxWidth="md">
            <Typography variant="h6" gutterBottom>
                Group Members
            </Typography>
            <List>
                {members.map((member) => (
                    <ListItem key={member.userId} divider>
                        <ListItemAvatar>
                            <Avatar src={member.avatarUrl || "/static/images/avatar/1.jpg"} />
                        </ListItemAvatar>
                        <ListItemText primary={member.name} secondary={member.role || 'Member'} />
                    </ListItem>
                ))}
            </List>
        </Container>
    );
};

export default GroupProfiles;
