import React, { useState } from 'react';
import {
  CircularProgress,
  List,
  ListItem,
  Button,
  ListItemText,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
  Alert,
  LinearProgress,
  Paper
} from '@mui/material';
import { getIdToken } from '../services/firebaseServices';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { CloudUpload } from '@mui/icons-material';

const Post = ({ projectId, update }) => {
  //const project = useOutletContext();

  const [feedItems, setFeedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [newPost, setNewPost] = useState('');
  const [files, setFiles] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);
  const [fileErrors, setFileErrors] = useState([]);
  const [uploadedFileIds, setUploadedFileIds] = useState([]);

  const handleAddPostDialog = () => {
    setNewPost('');
    setFiles([]);
    setFileErrors([]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setNewPost(value);
  };

  const handleFileUpload = async (file) => {
    if (file.size > 10 * 1024 * 1024) {
      setFileErrors((prev) => [...prev, `${file.name} exceeds the 10MB limit.`]);
      return;
    }

    try {
      setFileLoading(true);
      const reader = new FileReader();

      reader.onabort = () => console.log('File reading was aborted');
      reader.onerror = () => console.log('File reading has failed');
      reader.onload = async () => {
        const binaryStr = reader.result;
        const token = await getIdToken();

        const header = {
          "Content-Type": file.type || 'text/plain',
          "File-Name": file.name,
          'Authorization': `${token}`,
        };

        try {
          const response = await axios.post(`https://api.blueprintportal.net/projects/${projectId}/files`, binaryStr, { headers: header });
          setUploadedFileIds((prev) => [...prev, response.data.id]);
          setFiles((prev) => [...prev, { file, id: response.data.id }]);
        } catch (error) {
          console.error('Error uploading file:', error);
          setFileErrors((prev) => [...prev, `Failed to upload ${file.name}.`]);
        } finally {
          setFileLoading(false);
        }
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error('Error uploading file:', error);
      setFileErrors((prev) => [...prev, `Failed to upload ${file.name}.`]);
    }
  };

  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    selectedFiles.forEach(handleFileUpload);
  };

  const handleFileRemove = (id) => {
    setFiles((prev) => prev.filter((file) => file.id !== id));
    setUploadedFileIds((prev) => prev.filter((fileId) => fileId !== id));
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    droppedFiles.forEach(handleFileUpload);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleSubmit = async () => {
    if (fileLoading) {
      setError('Please wait until all files are uploaded.');
      return;
    }

    try {
      setLoading(true);
      const token = await getIdToken();
      const config = {
        method: 'post',
        url: `https://api.blueprintportal.net/projects/${projectId}/feed`,
        headers: { 'Authorization': `${token}`, 'Content-Type': 'application/json' },
        data: {
          description: newPost,
          title: "Post",
          files: uploadedFileIds,
        },
      };

      await axios(config);
      update();
      handleClose();
    } catch (err) {
      console.error('Error adding new post:', err);
      setError('Failed to add new post.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Button color="primary" onClick={handleAddPostDialog}>
        Post Update
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Add New Post</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a new post to the project feed, please enter your post content and upload any files if necessary.
          </DialogContentText>
          <TextField
            margin="dense"
            name="Post"
            label="Post"
            type="text"
            fullWidth
            multiline
            minRows={4}
            value={newPost}
            onChange={handleInputChange}
          />
          <Paper
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            sx={{
              border: '2px dashed grey',
              padding: 2,
              marginTop: 2,
              textAlign: 'center',
              backgroundColor: '#f9f9f9',
            }}
          >
            <Typography variant="body2" color="textSecondary">
              Drag & Drop files here or click below to upload
            </Typography>
            <Button
              variant="contained"
              component="label"
              startIcon={<CloudUploadIcon />}
              disabled={fileLoading}
              sx={{ mt: 2 }}
            >
              Upload Files
              <input type="file" hidden multiple onChange={handleFileChange} />
            </Button>
          </Paper>
          {fileLoading && <LinearProgress sx={{ mt: 1 }} />}
          {fileErrors.length > 0 && (
            <Box sx={{ mt: 2 }}>
              {fileErrors.map((error, index) => (
                <Alert severity="error" key={index}>
                  {error}
                </Alert>
              ))}
            </Box>
          )}
          <List>
            {files.map((file) => (
              <ListItem key={file.id} secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => handleFileRemove(file.id)}>
                  <DeleteIcon />
                </IconButton>
              }>
                <ListItemText primary={file.file.name} />
                {fileLoading && <CircularProgress size={24} />}
              </ListItem>
            ))}
          </List>
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={fileLoading || loading}>
            Post
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Post;
