import React, { useState } from 'react';
import axios from 'axios';
import { getIdToken } from '../services/firebaseServices'; // Adjust the path as necessary
import { Container, TextField, Button, Typography, CircularProgress, Card, CardContent, List, ListItem, ListItemText } from '@mui/material';

const Search = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSearch = async () => {
        setLoading(true);
        setError('');
        try {
            const token = await getIdToken();
            const config = {
                method: 'get',
                url: `https://api.blueprintportal.net/search/${query}`,
                headers: { 'Authorization': `${token}` }
            };

            const response = await axios(config);
            setResults(response.data.results || []);
            setLoading(false);
        } catch (err) {
            console.error('Error during search:', err);
            setError('Failed to perform search');
            setLoading(false);
        }
    };

    const handleChange = (event) => {
        setQuery(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <Container component="main" maxWidth="md">
            <Typography variant="h4" gutterBottom>
                Search
            </Typography>
            <TextField
                fullWidth
                variant="outlined"
                label="Search..."
                value={query}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                margin="normal"
            />
            <Button onClick={handleSearch} variant="contained" color="primary" disabled={!query}>
                Search
            </Button>
            {loading && <CircularProgress />}
            {error && <Typography color="error">{error}</Typography>}
            <List sx={{ mt: 2 }}>
                {results.map((item, index) => (
                    <Card key={index} sx={{ mt: 1 }}>
                        <CardContent>
                            <Typography variant="h6">{item.name}</Typography>
                            <Typography variant="body2">{item.description}</Typography>
                        </CardContent>
                    </Card>
                ))}
            </List>
        </Container>
    );
};

export default Search;
