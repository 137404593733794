import React from 'react';

const View = () => {
  return (
    <div >
      Feature coming soon
    </div>
  );
};

export default View;
