import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  TextField,
  Container,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchOutlinedIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import GroupIcon from '@mui/icons-material/Group';
import LockIcon from '@mui/icons-material/Lock';
import BuildIcon from '@mui/icons-material/Build';
import FeedIcon from '@mui/icons-material/Feed';
import SecurityIcon from '@mui/icons-material/Security';
import { NavLink, useLocation } from 'react-router-dom';

const HomePage = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      
      <Container maxWidth="lg" style={{ marginTop: 24 }}>
              <Typography variant="h4" gutterBottom>
                Welcome to Blueprint Portal
              </Typography>
              <Typography variant="body1" paragraph>
                Blueprint Portal is a sophisticated project proposal platform designed to function as a social media-like environment for sharing and managing ideas. The platform is geared towards users who wish to develop, track, and share project proposals within a controlled, collaborative environment.
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    
                    <Typography variant="h5" gutterBottom sx={{textAlign:"center"}}>
                        Entrepreneurs
                    </Typography>
                            
                    <Typography variant="body1" paragraph>
                        Entrepreneurs can use Blueprint Portal to develop business ideas, share them with potential investors, and collaborate with like-minded individuals.
                    </Typography>
                
                </Grid>
                
                <Grid item xs={12} md={4}>
                    
                    <Typography variant="h5" gutterBottom sx={{textAlign:"center"}}>
                    Researchers
                    </Typography>
                            
                    <Typography variant="body1" paragraph>
                    Researchers can use Blueprint Portal to develop business ideas, share them with potential investors, and collaborate with like-minded individuals. 
                    </Typography>
                
                </Grid>

                
                
                <Grid item xs={12} md={4}>
                    
                    <Typography variant="h5" gutterBottom sx={{textAlign:"center"}}>
                    Creative Professionals
                    </Typography>
                            
                    <Typography variant="body1" paragraph>
                    Creative Professionals such as writers, artists, and designers can share their creations, receive feedback, and create collaborative communities around their work.
                    </Typography>
                
                </Grid>

                </Grid>
                  <Typography variant="h5" gutterBottom>
                    Key Features
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="User Profiles"
                        secondary="Each user has a profile where they can showcase their projects and ideas. This allows for a personal touch and a centralized spot for all individual activity."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <AssignmentIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Projects"
                        secondary="Users can create, manage, and share projects. Each project can have its own set of documents, tasks, timelines, and discussion forums. The system supports various levels of access, from public viewing to private access requiring Non-Disclosure Agreements (NDAs)."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <GroupIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Groups"
                        secondary="Users can create or join groups. These groups can be public or private and are a way to organize users around shared interests or collaborative projects. Groups have their own profiles, showing all members and shared projects."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <LockIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="NDAs"
                        secondary="Certain projects or groups can require members to sign a Non-Disclosure Agreement to participate or view content. The platform handles the signing and management of these NDAs digitally."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <BuildIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Collaboration Tools"
                        secondary="The platform includes various collaboration tools such as whiteboards, to-do lists, and file management systems to facilitate efficient teamwork within projects."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FeedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Notifications and Feeds"
                        secondary="Users receive notifications related to their projects and groups. There's also a main feed where users can see updates from all subscribed projects and groups, similar to a social media feed."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SecurityIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Permissions and Roles"
                        secondary="Users can assign roles with specific permissions within their projects. This ensures that sensitive information is safeguarded and that project management can be delegated."
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SearchOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Search Functionality"
                        secondary="Users can search across various entities such as other users, projects, and groups, making it easy to find relevant projects and collaborators."
                      />
                    </ListItem>
                  </List>
                  

               
                  <Typography variant="h5" gutterBottom>
                Why Use Blueprint Portal?
              </Typography>
              <Typography variant="body1" paragraph>
                Blueprint Portal leverages the power of social interaction combined with robust project management tools to help you attract interest and investment. Here’s why you should use Blueprint Portal:
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>Showcase Your Ideas:</strong> With personalized user profiles, you can effectively showcase your projects and ideas, making it easier for investors and collaborators to understand your vision.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>Efficient Collaboration:</strong> The platform provides a suite of collaboration tools, including whiteboards, to-do lists, and file management systems, ensuring your team can work together seamlessly and efficiently.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>Secure and Private:</strong> With features like NDAs and role-based permissions, you can control who sees your sensitive information and ensure that your intellectual property is protected.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>Stay Informed:</strong> Receive real-time notifications about your projects and groups, so you’re always in the loop about the latest developments and can react promptly.
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>Find the Right People:</strong> The search functionality allows you to easily find other users, projects, and groups, helping you connect with potential collaborators and investors who share your interests.
              </Typography>
              <Typography variant="body1" paragraph>
                Blueprint Portal stands out by integrating the social interaction of a networking site with the structured management tools of a project management application, all while emphasizing security and privacy. This makes it an ideal platform for anyone looking to develop and promote their projects in a collaborative and controlled environment.
              </Typography>


      </Container>
    </>
  );
};

export default HomePage;
