// Logout.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../services/firebaseServices'; // Adjust the path as necessary

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const logOutUser = async () => {
            await logout();
            navigate('/');
        };

        logOutUser();
    }, [navigate]);

    return null; // Render nothing, just perform actions
};

export default Logout;
