import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getIdToken } from '../services/firebaseServices'; // Adjust the path as necessary
import { Container, Typography, CircularProgress, Card, CardContent, Divider,Box } from '@mui/material';
import { useParams, useNavigate, Outlet, Link } from 'react-router-dom';
import TimeAgo from 'react-timeago'
import Feed from "../components-dashboard/Feed"


const Home = () => {
    const [feedItems, setFeedItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchFeed = async () => {
            try {
                const token = await getIdToken();
                const config = {
                    method: 'get',
                    url: 'https://api.blueprintportal.net/feed',
                    headers: { 'Authorization': `${token}` }
                };

                const response = await axios(config);
                setFeedItems(response.data || []);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching feed:', err);
                setError('Failed to load feed');
                setLoading(false);
            }
        };

        fetchFeed();
    }, []);

    if (loading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <Typography color="error">{error}</Typography>
            </Container>
        );
    }

    return (
        <Container component="main" maxWidth="md">
            <Typography variant="h4" gutterBottom>
                Main Feed
            </Typography>


            
<Feed feedItems={feedItems}/>

            
        </Container>
    );
};

export default Home;
