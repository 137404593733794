import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CircularProgress,Card,CardContent,Button, Grid, List, ListItem, ListItemText, Typography, IconButton } from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import { getIdToken } from '../../../services/firebaseServices';  // Adjust the path to where firebaseServices is defined
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';


const ProjectList = () => {
    const { groupId } = useParams();
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [viewMode, setViewMode] = useState('grid'); // Default view mode

    const navigate = useNavigate();




    useEffect(() => {
        // Function to fetch projects
        const fetchProjects = async () => {
            setLoading(true);
            try {
                const token = await getIdToken();  // Get the ID token
                const config = {
                    method: 'get',
                    url: `https://api.blueprintportal.net/groups/${groupId}/projects`,
                    headers: { 'Authorization': `${token}` ,'Content-Type': 'application/json'}
                };

                const response = await axios(config);

                if(response.data && response.data.projects)
                    setProjects(response.data.projects);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching projects:', err);
                setError('Failed to load projects');
                setLoading(false);
            }
        };

        fetchProjects();
    }, []);


    /*
    const handleAddProject = () => {
        navigate('/project/add'); // Assuming the route for adding a project is '/add-project'
    };*/

    const handleProjectClick = (projectId) => {
        navigate(`/projects/${projectId}`);
    };
    
    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    

    return (
        <div style={{ padding: 20 }}>
            <Typography variant="h6" gutterBottom>
                Projects
            </Typography>
            {/*<Button variant="contained" color="primary" onClick={handleAddProject} style={{ marginBottom: 20 }}>
                Add Project
    </Button>*/}
            <IconButton onClick={() => setViewMode('grid')} color={viewMode === 'grid' ? 'primary' : 'default'}>
                <ViewModuleIcon />
            </IconButton>
            <IconButton onClick={() => setViewMode('list')} color={viewMode === 'list' ? 'primary' : 'default'}>
                <ViewListIcon />
            </IconButton>

            {viewMode === 'grid' ? (
                <Grid container spacing={3}>
                    {projects.map((project) => (
                        <Grid item key={project.id} xs={12} sm={6} md={4} onClick={() => handleProjectClick(project.id)}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        {project.title}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        {project.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <List>
                    {projects.map((project) => (
                        <ListItem key={project.id} alignItems="flex-start" button onClick={() => handleProjectClick(project.id)}>
                            <ListItemText
                                primary={project.title}
                                secondary={project.description}
                            />
                        </ListItem>
                    ))}
                </List>
            )}
        </div>
    );
};

export default ProjectList;

