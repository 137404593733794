import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getIdToken } from '../../../services/firebaseServices'; // Adjust the path to where firebaseServices is defined
import { Button, TextField, Typography, Container, Paper, CircularProgress, Box } from '@mui/material';

import {   Select,MenuItem, InputLabel,FormControl,  Switch, FormControlLabel, FormGroup, Checkbox } from '@mui/material';


const GroupSettings = () => {
    const { groupId } = useParams();
    const [groupDetails, setGroupDetails] = useState({ name: '', description: '' });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchGroupDetails = async () => {
            try {
                const token = await getIdToken();
                const config = {
                    method: 'get',
                    url: `https://api.blueprintportal.net/groups/${groupId}`,
                    headers: { 'Authorization': `${token}` }
                };

                const response = await axios(config);

                setGroupDetails({ name: response.data.name, description: response.data.description });
                setLoading(false);
            } catch (err) {
                console.error('Error fetching group details:', err);
                setError('Failed to load group details');
                setLoading(false);
            }
        };

        fetchGroupDetails();
    }, [groupId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setGroupDetails(prevDetails => ({ ...prevDetails, [name]: value }));
    };

    
    const handleInputChange = (event) => {
        const { name, value, checked, type } = event.target;
        setGroupDetails(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const token = await getIdToken();
            const config = {
                method: 'put',
                url: `https://api.blueprintportal.net/groups/${groupId}`,
                headers: {
                    'Authorization': `${token}`,
                    'Content-Type': 'application/json'
                },
                data: groupDetails
            };

            await axios(config);
            setLoading(false);
            alert('Group details updated successfully!');
        } catch (err) {
            console.error('Error updating group details:', err);
            setError(err.message || 'Failed to update group details');
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <Typography color="error">{error}</Typography>
            </Container>
        );
    }

    return (
        <Container component="main" maxWidth="md">
                <Typography variant="h6" gutterBottom>
                    Group Settings
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="name"
                        label="Group Name"
                        type="text"
                        id="name"
                        value={groupDetails.name}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="description"
                        label="Description"
                        type="text"
                        id="description"
                        value={groupDetails.description}
                        onChange={handleChange}
                    />
                    
            <FormControlLabel
                control={<Switch checked={groupDetails.private || false} onChange={handleInputChange} name="private" />}
                label="Private"
            />
            <br/>
            <FormControlLabel
                control={<Switch checked={groupDetails.ndaRequired || false} onChange={handleInputChange} name="ndaRequired" />}
                label="NDA Required"
            />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Update Settings
                    </Button>
                </Box>
        </Container>
    );
};

export default GroupSettings;
