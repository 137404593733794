import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress, List, ListItem, Button, ListItemText, Typography, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { getIdToken } from '../../../services/firebaseServices';
import {  Link } from 'react-router-dom';
import Feed from "../../../components-dashboard/Feed"



const ProjectFeed = () => {
    const { groupId } = useParams();  // Get the project ID from URL parameters
    const [feedItems, setFeedItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false);
    const [newPost, setNewPost] = useState("");

    useEffect(() => {
        

        fetchFeedItems();
    }, [groupId]);




    const fetchFeedItems = async () => {
        try {

          
          const token = await getIdToken();
          const config = {
              method: 'get',
              url: `https://api.blueprintportal.net/groups/${groupId}/feed`,
              headers: { 'Authorization': `${token}` }
          };

          const response = await axios(config);

            // Assume the API endpoint to fetch project feed is set up as shown
           // const response = await axios.get(`https://api.blueprintportal.net/projects/${groupId}/feed`);
            setFeedItems(response.data ? response.data : []);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching project feed:', err);
            setError('Failed to load project feed');
            setLoading(false);
        }
    };




    
    const handleAddPostDialog = () => {
        
        setNewPost("");
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleInputChange = (e) => {
        const { value } = e.target;
        setNewPost(value);
    };


    
    const handleSubmit = async () => {
        try {
            const token = await getIdToken();
            const config = {
                method: 'post',
                url: `https://api.blueprintportal.net/groups/${groupId}/feed`,
                headers: { 'Authorization': `${token}`, 'Content-Type': 'application/json' },
                data: {post:newPost}
            };

            const response = await axios(config);
            setFeedItems([...feedItems, response.data]);
            fetchFeedItems()
            handleClose();
        } catch (err) {
            console.error('Error adding new post:', err);
            setError('Failed to add new post');
        }
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <div>
        {/*<Button color="primary" onClick={handleAddPostDialog}>
            Add post
        </Button>*/}


<Feed feedItems={feedItems}/>

            {/*<List>
                {feedItems.map((item, index) => (
                    <ListItem key={index}   sx={{textDecoration:"none"}} component={item.groupId || item.groupId ? Link: ListItem}  to={item.groupId ? `/projects/${item.groupId}` : item.groupId ? `/groups/${item.groupId}` : null}>
                    <ListItemText
                            primary={item.title}
                            secondary={item.description}
                        />
                    </ListItem>
                ))}
            </List>*/}




            
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add New Post</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To add a new post to the group feed, please enter here.
                    </DialogContentText>
                    <TextField
                        margin="dense"
                        name="Post"
                        label="Post"
                        type="text"
                        fullWidth
                        
                        multiline
                        minRows={4}

                        value={newPost}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        post
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ProjectFeed;
