import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getIdToken } from '../../../../services/firebaseServices';
import {
    Container, Typography, Button, CircularProgress, List, ListItem, ListItemText,
    ListItemSecondaryAction, IconButton, TextField, Dialog, DialogContent, DialogActions,
    DialogTitle, Box
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';

//import Markdown from 'react-markdown'


const ProjectMembers = () => {
    const { projectId } = useParams();
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [newTask, setNewTask] = useState({ title: '', description: '' });

    useEffect(() => {
        const fetchMembers = async () => {
            try {
                const token = await getIdToken();
                const config = {
                    method: 'get',
                    url: `https://api.blueprintportal.net/projects/${projectId}/members`,
                    headers: { 'Authorization': `${token}` }
                };

                const response = await axios(config);
                setMembers(response.data || []);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching members:', err);
                setError('Failed to load members');
                setLoading(false);
            }
        };

        fetchMembers();
    }, [projectId]);



    
    const handleUserAccept = async (UserId) => {
        try {
            const token = await getIdToken();
            const config = {
                method: 'put',
                url: `https://api.blueprintportal.net/projects/${projectId}/members/${UserId}`,
                headers: {
                    'Authorization': `${token}`,
                    'Content-Type': 'application/json'
                },
                data: {status:"accepted"}
            };

            const response = await axios(config);
        } catch (err) {
            console.error('Error adding task:', err);
            setError(err.message || 'Failed to add task');
        }
    };

    
    
    const handleUserDecline = async (UserId) => {
        try {
            const token = await getIdToken();
            const config = {
                method: 'put',
                url: `https://api.blueprintportal.net/projects/${projectId}/members/${UserId}`,
                headers: {
                    'Authorization': `${token}`,
                    'Content-Type': 'application/json'
                },
                data: {status:"declined"}
            };

            const response = await axios(config);
        } catch (err) {
            console.error('Error adding task:', err);
            setError(err.message || 'Failed to add task');
        }
    };










    const handleAddTaskDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleTaskChange = (e) => {
        const { name, value } = e.target;
        setNewTask(prevTask => ({ ...prevTask, [name]: value }));
    };

    const handleAddTask = async () => {
        if (!newTask.title || !newTask.description) {
            alert('Please fill in all fields.');
            return;
        }
        try {
            const token = await getIdToken();
            const config = {
                method: 'post',
                url: `https://api.blueprintportal.net/projects/${projectId}/members`,
                headers: {
                    'Authorization': `${token}`,
                    'Content-Type': 'application/json'
                },
                data: newTask
            };

            const response = await axios(config);
            setMembers([...members, response.data]);
            setNewTask({ title: '', description: '' }); // Reset form
            handleCloseDialog();
        } catch (err) {
            console.error('Error adding task:', err);
            setError(err.message || 'Failed to add task');
        }
    };

    const handleDeleteTask = async (taskId) => {
        try {
            const token = await getIdToken();
            const config = {
                method: 'delete',
                url: `https://api.blueprintportal.net/projects/${projectId}/members`,
                headers: { 'Authorization': `${token}` }
            };

            await axios(config);
            setMembers(members.filter(task => task.id !== taskId)); // Optimistically remove the task from the UI
        } catch (err) {
            console.error('Error deleting task:', err);
            setError('Failed to delete task');
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <main>
            <List>
                {members.map((task) => (
                    <ListItem key={task.id} divider>
                        <Box>
                        <Typography variant="h6">{task.name}</Typography>
                        <Typography variant="subtitle1">{task.email}</Typography>
                        <Typography variant="body1">{task.role_type} - {task.status}</Typography>
                        {
                            task.status == "requested" ?
                            <Box><Button onClick={()=>handleUserAccept(task.id)}>Accept</Button> <Button onClick={()=>handleUserDecline(task.id)}>Decline</Button></Box>
                            :
                            null
                        }
                        
                        </Box>
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="menu" >
                                <MenuIcon />
                            </IconButton>
                            
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteTask(task.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            
        </main>
    );
};

export default ProjectMembers;
