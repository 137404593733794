import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getIdToken } from '../../../services/firebaseServices';
import {
    Container, Typography, Button, CircularProgress, List, ListItem, ListItemText,
    ListItemSecondaryAction, IconButton, TextField, Dialog, DialogContent, DialogActions,
    DialogTitle, Box
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';

import Markdown from 'react-markdown'
import { useOutletContext } from "react-router-dom";


const ProjectTasks = () => {
    const { projectId } = useParams();
    const project = useOutletContext();
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [newTask, setNewTask] = useState({ title: '', description: '',type:"task" });

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const token = await getIdToken();
                const config = {
                    method: 'get',
                    url: `https://api.blueprintportal.net/projects/${projectId}/items?type=task`,
                    headers: { 'Authorization': `${token}` }
                };

                const response = await axios(config);
                setTasks(response.data || []);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching tasks:', err);
                setError('Failed to load tasks');
                setLoading(false);
            }
        };

        fetchTasks();
    }, [projectId]);

    const handleAddTaskDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleTaskChange = (e) => {
        const { name, value } = e.target;
        setNewTask(prevTask => ({ ...prevTask, [name]: value }));
    };

    const handleAddTask = async () => {
        if (!newTask.title || !newTask.description) {
            alert('Please fill in all fields.');
            return;
        }
        try {
            const token = await getIdToken();
            const config = {
                method: 'post',
                url: `https://api.blueprintportal.net/projects/${projectId}/items`,
                headers: {
                    'Authorization': `${token}`,
                    'Content-Type': 'application/json'
                },
                data: newTask
            };

            const response = await axios(config);
            setTasks([...tasks, response.data]);
            setNewTask({ title: '', description: '' }); // Reset form
            handleCloseDialog();
        } catch (err) {
            console.error('Error adding task:', err);
            setError(err.message || 'Failed to add task');
        }
    };

    const handleDeleteTask = async (taskId) => {
        try {
            const token = await getIdToken();
            const config = {
                method: 'delete',
                url: `https://api.blueprintportal.net/projects/${projectId}/item/${taskId}`,
                headers: { 'Authorization': `${token}` }
            };

            await axios(config);
            setTasks(tasks.filter(task => task.id !== taskId)); // Optimistically remove the task from the UI
        } catch (err) {
            console.error('Error deleting task:', err);
            setError('Failed to delete task');
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <main>
            
            {["owner","admin"].includes("role_type" in project ? project.role_type : "") ?
            <Button startIcon={<AddBoxIcon />}  color="primary" onClick={handleAddTaskDialog}>
                Add New Task
            </Button>
            :null}
            <List>
                {tasks.map((task) => (
                    <ListItem key={task.id} divider>
                        <Box>
                        <Typography variant="h6">{task.title}</Typography>

                        
                            <Markdown>
                                {task.description}
                            </Markdown>
                        </Box>
                        {["owner","admin"].includes("role_type" in project ? project.role_type : "") ?

                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteTask(task.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                        :null}
                    </ListItem>
                ))}
            </List>
            <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add New Task</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="title"
                        label="Task Title"
                        type="text"
                        fullWidth
                        name="title"
                        value={newTask.title}
                        onChange={handleTaskChange}
                    />
                    <TextField
                        margin="dense"
                        id="description"
                        label="Task Description"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        name="description"
                        value={newTask.description}
                        onChange={handleTaskChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddTask} color="primary">
                        Add Task
                    </Button>
                </DialogActions>
            </Dialog>
        </main>
    );
};

export default ProjectTasks;
