import React from 'react';
import { Container, Typography, Paper, Box, Grid, Avatar } from '@mui/material';

const About = () => {
    return (
        <Container component="main" maxWidth="md">
            <Paper elevation={6} sx={{ mt: 4, p: 3 }}>
                <Typography variant="h4" gutterBottom>
                    About Us
                </Typography>
                <Typography paragraph>
                    Welcome to Blueprint Portal, a platform dedicated to revolutionizing the way individuals and teams collaborate on projects and share innovative ideas.
                </Typography>
                <Typography paragraph>
                    Our mission is to provide a seamless and intuitive environment where creativity is fostered and protected. Whether you are a startup looking to organize your early-stage ideas or a large team managing complex projects, Blueprint Portal is designed to support your journey from concept to execution.
                </Typography>

                {/*}
                <Typography variant="h5" gutterBottom>
                    Meet the Team
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Avatar sx={{ width: 100, height: 100, mb: 2 }} src="/static/images/avatar/1.jpg" />
                            <Typography variant="h6">John Doe</Typography>
                            <Typography variant="subtitle1">Founder & CEO</Typography>
                            <Typography variant="body2" color="textSecondary">
                                John is the visionary behind Blueprint Portal, with over 20 years of experience in project management and technology.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Avatar sx={{ width: 100, height: 100, mb: 2 }} src="/static/images/avatar/2.jpg" />
                            <Typography variant="h6">Jane Smith</Typography>
                            <Typography variant="subtitle1">Chief Technology Officer</Typography>
                            <Typography variant="body2" color="textSecondary">
                                Jane leads our technology strategy, bringing with her a wealth of experience in software development and systems architecture.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Avatar sx={{ width: 100, height: 100, mb: 2 }} src="/static/images/avatar/3.jpg" />
                            <Typography variant="h6">Emily Zhang</Typography>
                            <Typography variant="subtitle1">Head of Design</Typography>
                            <Typography variant="body2" color="textSecondary">
                                Emily oversees the design team and ensures our platform is not only functional but also aesthetically pleasing.
                            </Typography>
                        </Box>
                    </Grid>
    </Grid>*/}
            </Paper>
        </Container>
    );
};

export default About;
