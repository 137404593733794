// Footer.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

const Footer = () => {
    return (
        <Box sx={{     paddingBottom: "5px!important;",
            background: '#fff;',
            bottom: "0!important;",
            color: "#333!important;",
            fontSize: "14px!important;",
            paddingTop: "5px;",
            position: "absolute;",
            textAlign: "center!important;",
            width: "100%;",
            textDecoration: "none;"}}>
            <footer>
            <Typography variant="caption">
                <NavLink  to="/terms">terms</NavLink> • <NavLink  to="/privacy">privacy</NavLink> 
            </Typography>

            <br/>
            <Typography variant="caption">  Made by <a href="https://spried.com/" target='_blank'>SPRIED</a></Typography>
            </footer>
        </Box>
    );
};

export default Footer;
