import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Typography, Container, Paper, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getIdToken } from '../../services/firebaseServices'; // Adjust the path to where firebaseServices is defined

const GroupAdd = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const token = await getIdToken();  // Get the ID token
            const config = {
                method: 'post',
                url: 'https://api.blueprintportal.net/groups',
                headers: {
                    'Authorization': `${token}`,
                    'Content-Type': 'application/json'
                },
                data: {
                    name,
                    description
                }
            };

            await axios(config);
            navigate('/groups');  // Redirect to the groups list page after successful creation
        } catch (err) {
            console.error('Error creating group:', err);
            setError(err.message || 'Failed to create group');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="sm">
            <Paper elevation={6} sx={{ p: 3, mt: 3 }}>
                <Typography component="h1" variant="h5">
                    Create New Group
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Group Name"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        value={name}
                        onChange={handleNameChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="description"
                        label="Description"
                        type="text"
                        id="description"
                        autoComplete="description"
                        value={description}
                        onChange={handleDescriptionChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={loading}
                    >
                        Create Group
                    </Button>
                    {error && <Typography color="error">{error}</Typography>}
                </Box>
            </Paper>
        </Container>
    );
};

export default GroupAdd;
