import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

const ProjectSettings = () => {
    const { projectId } = useParams();

    return (
        <Box p={3}>
            Coming Soon.
        </Box>
    );
};

export default ProjectSettings;
