import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getIdToken } from '../../services/firebaseServices'; // Adjust the path as necessary
import { TextField, Button, Container, Typography, Paper, Box, CircularProgress } from '@mui/material';
import { useParams, useNavigate, Outlet, Link } from 'react-router-dom';


const Settings = () => {
    const [userSettings, setUserSettings] = useState({
        username: '',
        email: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchUserSettings = async () => {
            try {
                setLoading(true);
                const token = await getIdToken();
                const config = {
                    method: 'get',
                    url: 'https://api.blueprintportal.net/user',
                    headers: { 'Authorization': `${token}` }
                };
                const response = await axios(config);
                setUserSettings({ ...userSettings,  email: response.data.email });
                setLoading(false);
            } catch (err) {
                console.error('Error fetching user settings:', err);
                setError('Failed to load user settings');
                setLoading(false);
            }
        };

        fetchUserSettings();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserSettings(prevSettings => ({
            ...prevSettings,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (userSettings.newPassword !== userSettings.confirmPassword) {
            setError('Passwords do not match!');
            return;
        }

        try {
            setLoading(true);
            const token = await getIdToken();
            const config = {
                method: 'put',
                url: 'https://api.blueprintportal.net/user',
                headers: {
                    'Authorization': `${token}`,
                    'Content-Type': 'application/json'
                },
                data: {
                    username: userSettings.username,
                    email: userSettings.email,
                    password: userSettings.newPassword
                }
            };

            await axios(config);
            setLoading(false);
            alert('Settings updated successfully!');
        } catch (err) {
            console.error('Error updating settings:', err);
            setError(err.message || 'Failed to update settings');
            setLoading(false);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Container component="main" maxWidth="md">
            <Paper elevation={6} sx={{ p: 3, mt: 3 }}>
                <Typography variant="h6" gutterBottom>
                    User Settings
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={userSettings.email}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        name="newPassword"
                        label="New Password"
                        type="password"
                        id="newPassword"
                        autoComplete="new-password"
                        value={userSettings.newPassword}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        name="confirmPassword"
                        label="Confirm New Password"
                        type="password"
                        id="confirmPassword"
                        autoComplete="new-password"
                        value={userSettings.confirmPassword}
                        onChange={handleChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Update Settings
                    </Button>

                    
                    <Button
                    component={Link}
                    to="/logout"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Logout
                    </Button>


                    {error && <Typography color="error">{error}</Typography>}
                </Box>
            </Paper>
        </Container>
    );
};

export default Settings;
