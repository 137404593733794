import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import General from './Settings/General';
import Groups from './Settings/Groups';
import Profiles from './Settings/Profiles';
import ShareLinks from './Settings/ShareLinks';
import Nda from './Settings/Nda';
import Project from './Settings/Project';




const TabsArray=[
  {label:"General", view:General},
  {label:"Projects", view:Project},
  {label:"Profiles", view:Profiles},
  {label:"Groups", view:Groups},
  {label:"ShareLinks", view:ShareLinks},
  {label:"NDA", view:Nda}
]



interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex',}}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        {TabsArray.map((item,index)=>( <Tab label={item.label} value={index}  />))}
        
      </Tabs>


      
      {TabsArray.map((item,index)=>{ 
        const View = item.view
        return(
      <TabPanel value={value} index={index}>
      <View/>
    </TabPanel>
)})}


    </Box>
  );
}
