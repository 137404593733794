import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { getIdToken ,getUser} from '../../services/firebaseServices'; // Adjust the path to where firebaseServices is defined
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth'; // Only if using react-firebase-hooks
import { auth } from '../../helpers/firebaseConfig';



const Profile = () => {
    const { userId } = useParams(); // This may be undefined if it's the logged-in user's profile
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [following, setFollowing] = useState(false);
    const [isOwnProfile, setIsOwnProfile] = useState(false);
    const [user, authLoading, authError] = useAuthState(auth); // Get user state

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                // Assuming you have an endpoint to get user profile, and another to check following status
                const userProfileId = userId; // Use 'current' or similar if API supports fetching current user profile directly
                
                const token = await getIdToken();
                const config = {
                    method: 'get',
                    url: userProfileId ? `https://api.blueprintportal.net/user/${userProfileId}`:'https://api.blueprintportal.net/user',
                    headers: { 'Authorization': `${token}` }
                };

                const { data } = await axios(config);

                //const { data } = await axios.get(`https://api.yoursite.com/users/${userProfileId}`);
                setProfile(data);
                setFollowing(data.following);
                setIsOwnProfile(userProfileId?0:1); // Assume your API returns the user id in user details
                setLoading(false);
            } catch (error) {
                console.error('Error fetching profile:', error);
                setLoading(false);
            }
        };

        fetchProfile();
    }, [userId]);

    const handleFollowToggle = async () => {
        setLoading(true);
        try {
            const endpoint = following ? 'unfollow' : 'follow';
            await axios.post(`https://api.yoursite.com/users/${userId}/${endpoint}`);
            setFollowing(!following);
            setLoading(false);
        } catch (error) {
            console.error('Error updating follow status:', error);
            setLoading(false);
        }
    };

    if (loading) return <CircularProgress />;

    return (
        <Box p={3}>
            <Typography variant="h4">{profile.name ? profile.name : user&&user.displayName? user.displayName:"No Name"}</Typography>
            
            {!isOwnProfile && (
                <Button variant="contained" color="primary" onClick={handleFollowToggle}>
                    {following ? 'Unfollow' : 'Follow'}
                </Button>
            )}

            
            {isOwnProfile && (
                <Button variant="contained" color="primary" component={Link} to={"/settings"} >Edit</Button>
            )}

            <Typography variant="body1">
                {profile.description ? profile.description : "No description"}</Typography>
        </Box>
    );
};

export default Profile;
