// Header.js
import React, { useEffect,useState } from 'react';
import { Button,Box, List,Avatar, ListItem, ListItemIcon, ListItemText, IconButton, Toolbar, AppBar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MailIcon from '@mui/icons-material/Mail';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Footer from './Footer';  // Import the footer component
import { NavLink, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth'; // Only if using react-firebase-hooks

import { auth } from '../helpers/firebaseConfig';
import SettingsIcon from '@mui/icons-material/Settings';
import { getFirestore, collection, query, where, onSnapshot } from 'firebase/firestore';


import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SupportIcon from '@mui/icons-material/Support';
import ContactMailIcon from '@mui/icons-material/ContactMail';

import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import GroupIcon from '@mui/icons-material/Group';






const Header = () => {
    
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };


    return (<AppBar position="static" sx={{background:"#fff"}}>
    <Toolbar>
        <Box
            component={NavLink}
            to="/">
      <img src="/logo.png" alt="Blueprint Portal Logo" style={{ height: 40, marginRight: 16 }} />
      </Box>
      <Typography 
            component={NavLink}
            to="/" variant="h6" style={{ flexGrow: 1,textDecoration:"none" }}  sx={{color:"#034ead"}}>
        Blueprint<strong>Portal</strong>
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
            component={NavLink}
            to="/login"
          edge="end"
          color="inherit"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          sx={{color:"#034ead"}}
          onClick={handleMenu}
        >
          Login
        </Button>
      </div>
    </Toolbar>
  </AppBar>
    );
};

export default Header;
