// Footer.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

const Footer = () => {
    return (
        <footer style={{ padding: 16, marginTop: 24, textAlign: 'center', backgroundColor: '#f5f5f5' }}>
          <Typography variant="body2" color="textSecondary">
            &copy; {new Date().getFullYear()} Blueprint Portal. All rights reserved.
          </Typography>
          <NavLink  to="/terms">terms</NavLink> • <NavLink  to="/privacy">privacy</NavLink> 
  
        </footer>
    );
};

export default Footer;
