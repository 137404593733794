import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress, List, ListItem, Button, ListItemText, Typography, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { getIdToken } from '../../../services/firebaseServices';
import { Link } from 'react-router-dom';
import { useOutletContext } from "react-router-dom";
import parse from 'html-react-parser';
import Feed from "../../../components-dashboard/Feed"
import Post from "../../../components-dashboard/Post"

const ProjectFeed = () => {
    const { projectId } = useParams();  // Get the project ID from URL parameters
    const project = useOutletContext();

    const [feedItems, setFeedItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false);
    const [newPost, setNewPost] = useState("");

    useEffect(() => {

        fetchFeedItems();
    }, [projectId]);

    const updateFeed = () => {
        fetchFeedItems()

    }

    
    const fetchFeedItems = async () => {
        try {
            const token = await getIdToken();
            const config = {
                method: 'get',
                url: `https://api.blueprintportal.net/projects/${projectId}/feed`,
                headers: { 'Authorization': `${token}` }
            };

            const response = await axios(config);
            setFeedItems(response.data ? response.data : []);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching project feed:', err);
            setError('Failed to load project feed');
            setLoading(false);
        }
    };



    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <div>
            
            {["owner","admin"].includes("role_type" in project ? project.role_type : "") ? 
                <Post /*project={project}*/ update={updateFeed}  projectId={projectId}/>
            :null}

            <Box>
            {parse(project.description)}

            </Box>

           <Feed feedItems={feedItems}/>


        </div>
    );
};

export default ProjectFeed;
