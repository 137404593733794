import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, TextField, Button,Select,MenuItem, Typography,InputLabel,FormControl, CircularProgress, Switch, FormControlLabel, FormGroup, Checkbox } from '@mui/material';
import { getIdToken } from '../../../../services/firebaseServices';

const ProjectSettings = () => {
    const { projectId } = useParams();
    const [project, setProject] = useState({});
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [groupChecks, setGroupChecks] = useState({});


    useEffect(() => {
        const fetchData = async () => {
            try {
              
              const token = await getIdToken();
              const config = {
                  method: 'get',
                  url: `https://api.blueprintportal.net/projects/${projectId}`,
                  headers: { 'Authorization': `${token}` }
              };

              const projectRes = await axios(config);


                //const projectRes = await axios.get(`https://api.blueprintportal.net/projects/${projectId}`);
                //const groupsRes = await axios.get('https://api.blueprintportal.net/groups');
                setProject(projectRes.data);
                //setGroups(groupsRes.data.groups);
                let initialChecks = {};
                /*groupsRes.data.groups.forEach(group => {
                    initialChecks[group.id] = project.groups.includes(group.id);
                });*/
                setGroupChecks(initialChecks);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [projectId]);

    const handleInputChange = (event) => {
        const { name, value, checked, type } = event.target;
        setProject(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleGroupChange = (groupId) => {
        setGroupChecks(prev => ({ ...prev, [groupId]: !prev[groupId] }));
    };

    const saveSettings = async () => {
        setLoading(true);
        try {

          const token = await getIdToken();
          const config = {
              method: 'put',
              url: `https://api.blueprintportal.net/projects/${projectId}`,
              headers: { 'Authorization': `${token}` },
              data: project
          };

          const response = await axios(config);

            alert('Settings updated successfully!');
        } catch (error) {
            console.error('Failed to update settings:', error);
        }
        setLoading(false);
    };

    if (loading) return <CircularProgress />;


    const Settings = [
        {label:"Viewer Settings",settings:[
            {id:"viewersCanInvite",label:"Viewers can invite",default:false},
            {id:"viewersPost",label:"Viewers post",default:false},
            {id:"viewersComment",label:"Viewers comment",default:false},
        ]}
    ]


    return (
        <Box p={3}>

            <br/>

            {Settings.map((item)=>(
                <Box>
                    <h3>{item.label}</h3>

                    {item.settings.map((settingsItem)=>(
                        <FormControlLabel
                            control={<Switch checked={settingsItem.id in project ? project[settingsItem.id] : settingsItem.default} onChange={handleInputChange} name={settingsItem.id} />}
                            label={settingsItem.label}
                        />
                    ))}

                </Box>
            ))}
            
            <Button variant="contained" color="primary" onClick={saveSettings} sx={{ mt: 2 }}>
                Save Settings
            </Button>
        </Box>
    );
};

export default ProjectSettings;
