import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate,useLocation, Outlet, Link } from 'react-router-dom';
import {
  Paper,
  Typography,
  Tab,Button,
  InputBase,
  Tabs,
  
  Container, CircularProgress, List, ListItem, ListItemText,
  ListItemSecondaryAction, IconButton, TextField, Dialog, DialogContent, DialogActions,
  DialogTitle, Box, Alert

} from '@mui/material';
import { getIdToken } from '../../services/firebaseServices';

const GroupDetail = () => {
    const { groupId } = useParams();
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [emailAddresses, setEmailAddresses] = useState('');
  const [emailError, setEmailError] = useState('');
  const [submitting, setSubmitting] = useState(false); // New state for email submission loading

  
  const navigate = useNavigate();
  const location = useLocation(); // Get current location


  const tabsAdmin = [
    { label: "Overview", path: "" },
    { label: "Profiles", path: "/profiles" },
    { label: "Projects", path: "/projects" },
    { label: "Settings", path: "/settings" },
  ];
  
  const tabsMember = [
    { label: "Overview", path: "" },
    { label: "Profiles", path: "/profiles" },
    { label: "Projects", path: "/projects" }
  ];


  const tabs =  ["owner","admin"].includes(group && "role_type" in group ? group.role_type : "") ? tabsAdmin :  tabsMember





  
  
  useEffect(() => {
    // Find the index of the current tab based on the path
    const currentTabPath = location.pathname.replace(`/groups/${groupId}`, '');
    const tabIndex = tabs.findIndex(tab => tab.path === currentTabPath);
    if (tabIndex !== -1) {
      setValue(tabIndex);
    }
  }, [location.pathname, groupId, tabs]);



  useEffect(() => {
    fetchGroupDetails();
  }, [groupId]);



  const validateEmails = (emails) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    for (let email of emails) {
      if (!emailPattern.test(email)) {
        return false;
      }
    }
    return true;
  };

  const handleInviteSubmit = async () => {
    const emails = emailAddresses.split(',').map(email => email.trim());
    if (emails.length === 0 || !validateEmails(emails)) {
      setEmailError('Please enter valid email addresses separated by commas.');
      return;
    }

    setSubmitting(true); // Set submitting to true

    try {
      const token = await getIdToken();
      const config = {
        method: 'post',
        url: `https://api.blueprintportal.net/groups/${groupId}/invite`,
        headers: { 'Authorization': `${token}` },
        data: { emails }
      };

      await axios(config);
      setOpenDialog(false);
      setEmailAddresses('');
      setEmailError('');
    } catch (error) {
      console.error('Failed to send invitations:', error);
      setEmailError('Failed to send invitations. Please try again.');
    } finally {
      setSubmitting(false); // Set submitting to false
    }
  };

  const fetchGroupDetails = async () => {
    setLoading(true);
    try {
        const token = await getIdToken();
                const config = {
                    method: 'get',
                    url: `https://api.blueprintportal.net/groups/${groupId}`,
                    headers: { 'Authorization': `${token}` }
                };

                const response = await axios(config);


      setGroup(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch group details:', error);
      setLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/groups/${groupId}${tabs[newValue].path}`);
  };







  
  const AcceptInvite = async () => {
    setLoading(true);
    try {
      const token = await getIdToken();
      const config = {
        method: 'post',
        url: `https://api.blueprintportal.net/groups/${groupId}/accept`,
        headers: { 'Authorization': `${token}` }
      };

      const response = await axios(config);
      if(response.data.success)
      {
        fetchGroupDetails()
      }
      else
      {
        setLoading(false);
      }
    } catch (error) {
      console.error('Failed to fetch project details:', error);
      setLoading(false);
    }
  };






  if (loading) {
    return (
      <Paper style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>
        <CircularProgress />
      </Paper>
    );
  }

  if (!group) {
    return (
      <Typography variant="h6" style={{ padding: 20 }}>
        Group not found
      </Typography>
    );
  }






  

  if(group.role_type == "invited")
    {
      return (
        <>
          <Paper style={{ padding: 20 }}>
            <Typography variant="h6" gutterBottom>
              You have been invited to join 
            </Typography>
            <Typography variant="h4" gutterBottom>
              {group.name}
            </Typography>
            
            <Button onClick={AcceptInvite} >Accept Invite</Button>
  
          </Paper>
        </>
      )
    }




  const urlInvite = `${window.location.protocol}//${window.location.hostname}/join/group/${groupId}`;

  return (
    <>
    <Paper style={{ padding: 20 }}>
      <Typography variant="h4" gutterBottom>
        {group.name}
      </Typography>

      

{(["owner","admin","member"].includes(group.role_type) ) ?
      <Button onClick={() => setOpenDialog(true)}>
          Invite
        </Button> : null}

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs   allowScrollButtonsMobile  variant="scrollable"  scrollButtons="auto" value={value} onChange={handleChange} aria-label="group details tabs">
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} component={Link} to={`/groups/${groupId}${tab.path}`} />
          ))}
        </Tabs>
      </Box>
      <Outlet />
    </Paper>


    <Dialog open={openDialog} onClose={() => setOpenDialog(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Invite</DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Invite Link
          </Typography>
          <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', mb: 2 }}>
            <InputBase sx={{ ml: 1, flex: 1 }} value={urlInvite} inputProps={{ 'aria-label': 'invite link' }} />
            <Button onClick={() => navigator.clipboard.writeText(urlInvite)} type="button" sx={{ p: '10px' }} aria-label="copy">
              Copy
            </Button>
          </Paper>

          <Typography variant="h6" gutterBottom>
            Invite by Email
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            label="Email Addresses"
            placeholder="Enter email addresses separated by commas"
            value={emailAddresses}
            onChange={(e) => setEmailAddresses(e.target.value)}
            error={Boolean(emailError)}
            helperText={emailError}
          />
          {emailError && <Alert severity="error">{emailError}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Close
          </Button>
          <Button onClick={handleInviteSubmit} color="primary" disabled={submitting}>
            {submitting ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default GroupDetail;






/*import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, NavLink, Outlet } from 'react-router-dom';
import axios from 'axios';
import { getIdToken } from '../../services/firebaseServices'; // Adjust the path to where firebaseServices is defined
import { Typography, Button, Box, Container, Paper, CircularProgress } from '@mui/material';

const GroupDetails = () => {
    const { groupId } = useParams();
    const [group, setGroup] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGroupDetails = async () => {
            setLoading(true);
            try {
                const token = await getIdToken();
                const config = {
                    method: 'get',
                    url: `https://api.blueprintportal.net/groups/${groupId}`,
                    headers: { 'Authorization': `${token}` }
                };

                const response = await axios(config);
                setGroup(response.data.group);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching group details:', err);
                setError('Failed to load group details');
                setLoading(false);
            }
        };

        fetchGroupDetails();
    }, [groupId]);

    if (loading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <Typography color="error">{error}</Typography>
            </Container>
        );
    }

    return (
        <Container component="main">
            <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
                <Typography variant="h4" gutterBottom>
                    {group?.name || "Group Details"}
                </Typography>
                <Typography variant="subtitle1">{group?.description}</Typography>

                <Box sx={{ mt: 2 }}>
                    <Button component={NavLink} to="" variant="contained" sx={{ mr: 1 }}>
                        Overview
                    </Button>
                    <Button component={NavLink} to="profiles" variant="contained" sx={{ mr: 1 }}>
                        Profiles
                    </Button>
                    <Button component={NavLink} to="settings" variant="contained">
                        Settings
                    </Button>
                </Box>

                <Outlet />  
            </Paper>
        </Container>
    );
};

export default GroupDetails;
*/