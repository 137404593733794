// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';  // Global styles
import App from './App'; // The root component
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';  // Assuming AuthContext is set up

import { ThemeProvider, createTheme } from '@mui/material/styles';

const themeLight = createTheme({
  palette: {
    
    primary: {
      main: '#034ead', // Custom primary color
    },
  }
});


ReactDOM.render(
  <React.StrictMode>
    
      <AuthProvider>  {/* Provides authentication context*/}
      <ThemeProvider theme={themeLight}>
        <App />
      </ThemeProvider>
      </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
