import React, { useState } from 'react';
import { json, useNavigate } from 'react-router-dom';
import { db } from '../../helpers/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { Button, TextField, Box, Typography } from '@mui/material';
import axios from 'axios';
import { getIdToken } from '../../services/firebaseServices';  // Adjust the path to where firebaseServices is defined

const View = () => {
    
    return (
        <Box
        >
        </Box>
    );
};

export default View;
