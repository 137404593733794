import React, { useState } from 'react';
import { json, useNavigate } from 'react-router-dom';
import { db } from '../../helpers/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { Button, MenuItem,TextField,InputLabel,Select,FormControl, Box, Typography } from '@mui/material';
import axios from 'axios';
import { getIdToken } from '../../services/firebaseServices';  // Adjust the path to where firebaseServices is defined


const states = [
    'Idea', 'Planning', 'Prototyping', 'Seeking Funding', 'In Development', 'In Testing', 'Seeking Interest', 'Tooling', 'Ready for Sale', 'Launching', 'Completed', 'Paused', 'Canceled'
];


const ProjectAdd = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [state, setState] = useState('Idea');
    //const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {

      event.preventDefault();
      setLoading(true);
      try {
          const token = await getIdToken();  // Get the ID token
          const config = {
              method: 'post',
              data:JSON.stringify({title:title,description:description}),
              url: 'https://api.blueprintportal.net/projects',
              headers: { 'Authorization': token,'Content-Type': 'application/json'}
          };

          const response = await axios.request(config);

          if(response.data && response.data.project)
            navigate(`/projects/${response.data.project.id}`);
          setLoading(false);

          
              // Redirect to the projects list view after adding

      } catch (err) {
          console.error('Error fetching projects:', err);
          setError('Failed to load projects');
          setLoading(false);
      }
    };

    return (
        <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
            style={{ margin: "20px" }}
        >
            <Typography variant="h6">Add New Project</Typography>
            <TextField
                margin="normal"
                required
                fullWidth
                id="title"
                label="Project Title"
                name="title"
                autoComplete="title"
                autoFocus
                value={title}
                onChange={e => setTitle(e.target.value)}
            />

            
<FormControl fullWidth margin="normal">
                <InputLabel  id="state-select-label">State</InputLabel>
                <Select
                    labelId="state-select-label"

                    label="State"
                    name="state"
                    value={state || 'Idea'}
                    onChange={e => setState(e.target.value)}
                >
                    {states.map(stateItem => (
                        <MenuItem key={stateItem} value={stateItem}>
                            {stateItem}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>


            <TextField
                margin="normal"
                required
                fullWidth
                id="description"
                label="Project Description"
                name="description"
                autoComplete="description"
                value={description}
                onChange={e => setDescription(e.target.value)}
                multiline
                rows={4}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Add Project
            </Button>
        </Box>
    );
};

export default ProjectAdd;
