import React from 'react';
import { Container, Typography, Paper, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EmailIcon from '@mui/icons-material/Email';
import { useParams, NavLink, Outlet } from 'react-router-dom';


const Support = () => {
    const faqs = [
        {
            question: "How do I reset my password?",
            answer: "Go to your profile settings and click on 'Reset Password'. Follow the instructions emailed to you."
        },
        {
            question: "What to do if I encounter a payment issue?",
            answer: "Contact our support immediately via the provided email or phone number below. Please have your transaction details handy for quicker assistance."
        },
        {
            question: "How can I delete my account?",
            answer: "If you need to delete your account, please send a request through our contact form, and we will process it within 48 hours."
        }
    ];

    return (
        <Container component="main" maxWidth="md">
            <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Support Center
                </Typography>
                <Typography paragraph>
                    Looking for help? Browse our frequently asked questions below or contact us for further assistance.
                </Typography>
                {faqs.map((faq, index) => (
                    <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{faq.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {faq.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
                <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
                    Direct Support
                </Typography>
                <Button 
                    component={NavLink}
                    to={"/contact"}
                    startIcon={<EmailIcon />}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 1 }}
                >
                    Email Us
                </Button>
            </Paper>
        </Container>
    );
};

export default Support;
