import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CircularProgress, Card, CardContent, Button, Grid, List, ListItem, ListItemText, Typography, IconButton } from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import { getIdToken } from '../../services/firebaseServices';  // Adjust the path to where firebaseServices is defined
import { useNavigate } from 'react-router-dom';

const GroupList = () => {
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [viewMode, setViewMode] = useState('grid'); // Default view mode
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGroups = async () => {
            setLoading(true);
            try {
                const token = await getIdToken();  // Get the ID token
                const config = {
                    method: 'get',
                    url: 'https://api.blueprintportal.net/groups',
                    headers: { 'Authorization': `${token}` }
                };

                const response = await axios(config);
                setGroups(response.data.groups || []);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching groups:', err);
                setError('Failed to load groups');
                setLoading(false);
            }
        };

        fetchGroups();
    }, []);

    const handleAddGroup = () => {
        navigate('/groups/add');
    };

    const handleGroupClick = (groupId) => {
        navigate(`/groups/${groupId}`);
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <div style={{ padding: 20 }}>
            <Typography variant="h4" gutterBottom>
                Groups
            </Typography>
            <Button variant="contained" color="primary" onClick={handleAddGroup} style={{ marginBottom: 20 }}>
                Add Group
            </Button>
            <IconButton onClick={() => setViewMode('grid')} color={viewMode === 'grid' ? 'primary' : 'default'}>
                <ViewModuleIcon />
            </IconButton>
            <IconButton onClick={() => setViewMode('list')} color={viewMode === 'list' ? 'primary' : 'default'}>
                <ViewListIcon />
            </IconButton>

            {viewMode === 'grid' ? (
                <Grid container spacing={3} >
                    {groups.map((group) => (
                        <Grid  item key={group.id} xs={12} sm={6} md={4} onClick={() => handleGroupClick(group.id)}>
                            <Card sx={{cursor:"pointer"}}>
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        {group.name}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        {group.role_type}
                                    </Typography>
                                    <hr/>
                                    <Typography color="textSecondary">
                                        {group.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <List>
                    {groups.map((group) => (
                        <ListItem key={group.id} alignItems="flex-start" button onClick={() => handleGroupClick(group.id)}>
                            <ListItemText
                                primary={group.name}
                                secondary={group.description}
                            />
                        </ListItem>
                    ))}
                </List>
            )}
        </div>
    );
};

export default GroupList;
