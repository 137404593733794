import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getIdToken } from '../../../services/firebaseServices';
import { Container, Typography, Button, CircularProgress, List, ListItem, ListItemText, IconButton, TextField, Dialog, DialogContent, DialogActions, DialogTitle, Box } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { useOutletContext } from "react-router-dom";


const ProjectFiles = () => {
    const { projectId } = useParams();
    const project = useOutletContext();
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [fileToUpload, setFileToUpload] = useState(null);

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const token = await getIdToken();
                const config = {
                    method: 'get',
                    url: `https://api.blueprintportal.net/projects/${projectId}/files`,
                    headers: { 'Authorization': `${token}` }
                };

                const response = await axios(config);
                setFiles(response.data || []);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching files:', err);
                setError('Failed to load files');
                setLoading(false);
            }
        };

        fetchFiles();
    }, [projectId]);

    const handleUploadDialogToggle = () => {
        setUploadDialogOpen(!uploadDialogOpen);
    };

    const handleFileChange = (event) => {
        setFileToUpload(event.target.files[0]);
    };

    const handleUploadFile = async () => {

        /*
        if (!fileToUpload) {
            alert('Please select a file to upload.');
            return;
        }
        const formData = new FormData();
        formData.append('file', fileToUpload);

        try {
            const token = await getIdToken();
            const config = {
                method: 'post',
                url: `https://api.blueprintportal.net/projects/${projectId}/files`,
                headers: {
                    'Authorization': `${token}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            };

            const response = await axios(config);
            setFiles([...files, response.data]);
            handleUploadDialogToggle();
        } catch (err) {
            console.error('Error uploading file:', err);
            setError(err.message || 'Failed to upload file');
        }*/




        

    if (!fileToUpload) return;

    const reader = new FileReader();

    reader.onabort = () => console.log('File reading was aborted');
    reader.onerror = () => console.log('File reading has failed');

    reader.onload = async () => {

      const binaryStr = reader.result;

      
      const token = await getIdToken();

      const header = {
        "Content-Type": fileToUpload.type || 'text/plain',
        // "X-API-Key": match.params.setId, Uncomment and adjust as needed
        "File-Name": fileToUpload.name,
        'Authorization': `${token}`
      };

      axios.post(`https://api.blueprintportal.net/projects/${projectId}/files`,binaryStr,{headers:header}).then(Result => {

        console.log(Result)

        setFiles([...files, Result.data]);
        handleUploadDialogToggle();
      alert('Firmware uploaded successfully!');
        
      }).catch(err => { 
          console.error("handleSubmit")
          console.error(err)
          alert("Error");
      });

    }


    reader.readAsArrayBuffer(fileToUpload)


    };

    const handleDownloadFile = async (fileId,filename="file") => {
        try {
            const token = await getIdToken();
            const url = `https://api.blueprintportal.net/files/${fileId}`;
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `${token}`
                },
                responseType: 'blob' // Important for files
            });

            // Create a URL and link to trigger download
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            //console.log(response)
            link.setAttribute('download', filename); // any filename: TODO
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (err) {
            console.error('Error downloading file:', err);
            setError('Failed to download file');
        }
    };

    const handleDeleteFile = async (fileId) => {
        try {
            const token = await getIdToken();
            const config = {
                method: 'delete',
                url: `https://api.blueprintportal.net/projects/${projectId}/files/${fileId}`,
                headers: { 'Authorization': `${token}` }
            };

            await axios(config);
            setFiles(files.filter(file => file.uuid !== fileId)); // Optimistically remove the file from UI
        } catch (err) {
            console.error('Error deleting file:', err);
            setError('Failed to delete file');
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <main >
            {["owner","admin"].includes("role_type" in project ? project.role_type : "") ? 
            <Button startIcon={<FileUploadIcon />} variant="contained" color="primary" onClick={handleUploadDialogToggle}>
                Upload New File
            </Button>
            :null}

            <List>
                {files.map((file) => (
                    <ListItem key={file.id} divider>
                        <ListItemText primary={file.file_name} secondary={`Uploaded: ${new Date(file.upload_date).toLocaleString()}`} />
                        <IconButton edge="end" aria-label="download" onClick={() => handleDownloadFile(file.uuid,file.file_name)}>
                            <DownloadIcon />
                        </IconButton>
                        
                        {["owner","admin"].includes("role_type" in project ? project.role_type : "") ?
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteFile(file.uuid)}>
                                <DeleteIcon />
                            </IconButton>
                        :null}
                    </ListItem>
                ))}
            </List>
            <Dialog open={uploadDialogOpen} onClose={handleUploadDialogToggle} aria-labelledby="upload-dialog-title">
                <DialogTitle id="upload-dialog-title">Upload File</DialogTitle>
                <DialogContent>
                    <input
                        type="file"
                        onChange={handleFileChange}
                        accept="*/*"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUploadDialogToggle} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUploadFile} color="primary">
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>
        </main>
    );
};

export default ProjectFiles;
