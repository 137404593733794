import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getIdToken } from '../../../../services/firebaseServices';
import {
    Container, Typography, Button, CircularProgress, List, ListItem, ListItemText,
    ListItemSecondaryAction, IconButton, TextField, Dialog, DialogContent, DialogActions,
    DialogTitle, Box, Alert
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import parse from 'html-react-parser';
import ReactQuill from "react-quill"
import 'quill/dist/quill.snow.css'; // Add css for snow theme

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


const ProjectNDAs = () => {
    const { projectId } = useParams();
    const [project, setProject] = useState({});
    const [NDAs, setNDAs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
    const [viewDialog, setViewDialog] = useState(false);
    const [viewLoading, setViewLoading] = useState(false);
    const [viewError, setViewError] = useState('');
    const [newNda, setNewNda] = useState("");
    const [newNdaEnable, setNewNdaEnabled] = useState(false);
    const [selectedNDA, setSelectedNDA] = useState(null);
    const [addLoading, setAddLoading] = useState(false);
    const [addError, setAddError] = useState('');

    const fetchData = async () => {
        try {
          
          const token = await getIdToken();
          const config = {
              method: 'get',
              url: `https://api.blueprintportal.net/projects/${projectId}`,
              headers: { 'Authorization': `${token}` }
          };

          const projectRes = await axios(config);


            //const projectRes = await axios.get(`https://api.blueprintportal.net/projects/${projectId}`);
            //const groupsRes = await axios.get('https://api.blueprintportal.net/groups');
            setProject(projectRes.data);
            //setGroups(groupsRes.data.groups);
            let initialChecks = {};
            /*groupsRes.data.groups.forEach(group => {
                initialChecks[group.id] = project.groups.includes(group.id);
            });*/
            //setGroupChecks(initialChecks);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };


    const fetchNDAs = async () => {
        try {
            const token = await getIdToken();
            const config = {
                method: 'get',
                url: `https://api.blueprintportal.net/projects/${projectId}/nda/list`,
                headers: { 'Authorization': `${token}` }
            };

            const response = await axios(config);
            setNDAs(response.data || []);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching NDAs:', err);
            setError('Failed to load NDAs');
            setLoading(false);
        }
    };

    useEffect(() => {
        
        fetchData();
        fetchNDAs();
    }, [projectId]);

    const handleAddTaskDialog = () => {
        setNewNda(" ");
        setAddError('');
        setNewNdaEnabled(true);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setNewNda(" ");
        setAddError('');
    };

    
    const handleEdit = (selectedNDA) => {
        setViewDialog(false);
        setNewNda(selectedNDA.contains);
        setNewNdaEnabled(selectedNDA.project);
        setAddError('');
        
        setOpenDialog(true);
    };

    
    const handleEnable = async (selectedNDA) => {
        setAddLoading(true);
        try {
            const token = await getIdToken();
            const config = {
                method: 'put',
                url: `https://api.blueprintportal.net/projects/${projectId}/nda`,
                headers: {
                    'Authorization': `${token}`,
                    'Content-Type': 'application/json'
                },
                data: {status:selectedNDA.id}
            };

            const response = await axios(config);
            setNDAs([...NDAs, response.data]);
            setNewNda(""); // Reset form
            handleCloseDialog();
        } catch (err) {
            console.error('Error adding task:', err);
            setAddError(err.message || 'Failed to add task');
        }
        fetchNDAs();
        setAddLoading(false);
    };

    


    const handleTextChange = (value) => {
        setNewNda(value);
    };

    const handleViewDialogClose = () => {
        setViewDialog(false);
        setSelectedNDA(null);
        setViewError('');
    };

    const handleView = async (id) => {
        setViewDialog(true);
        setViewLoading(true);
        setViewError('');
        try {
            const token = await getIdToken();
            const config = {
                method: 'get',
                url: `https://api.blueprintportal.net/projects/${projectId}/nda/${id}`,
                headers: { 'Authorization': `${token}` }
            };

            const response = await axios(config);
            setSelectedNDA(response.data);
            setViewLoading(false);
        } catch (err) {
            console.error('Error fetching NDA:', err);
            setViewError('Failed to load NDA');
            setViewLoading(false);
        }
    };

    

    

    const handleRemoveNdaTask = async () => {
        setAddLoading(true);
        try {
            const token = await getIdToken();
            const config = {
                method: 'put',
                url: `https://api.blueprintportal.net/projects/${projectId}/nda`,
                headers: {
                    'Authorization': `${token}`,
                    'Content-Type': 'application/json'
                },
                data: {status:null}
            };

            const response = await axios(config);
            setOpenRemoveDialog(false);
        } catch (err) {
            console.error('Error adding task:', err);
            setAddError(err.message || 'Failed to add task');
        }
        setAddLoading(false);
    };

    

    const handleAddTask = async () => {
        setAddLoading(true);
        try {
            const token = await getIdToken();
            const config = {
                method: 'post',
                url: `https://api.blueprintportal.net/projects/${projectId}/nda`,
                headers: {
                    'Authorization': `${token}`,
                    'Content-Type': 'application/json'
                },
                data: {contents:newNda, setForProject:newNdaEnable}
            };

            const response = await axios(config);
            setNDAs([...NDAs, response.data]);
            setNewNda(""); // Reset form
            handleCloseDialog();
        } catch (err) {
            console.error('Error adding task:', err);
            setAddError(err.message || 'Failed to add task');
        }
        setAddLoading(false);
        fetchNDAs();
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <main>
            <Container>
                <Box sx={{display:"flex"}}>
                    <Button variant="contained" color="primary" startIcon={<AddBoxIcon />} onClick={handleAddTaskDialog}>
                        Add 
                    </Button>

                    <Box sx={{flexGrow:1}}/>
                    {project && project.nda_id ?
                    <Button variant="contained" color="primary" onClick={()=>setOpenRemoveDialog(true)}>
                        Remove 
                    </Button> :null}

                </Box>


                <List>
                    {NDAs.map((task) => (
                        <ListItem sx={{background:task.project?"#eee":"none"}} key={task.id} divider onClick={() => handleView(task.id)}>
                            <Box>
                                <Typography variant="h6">{task.created}</Typography>
                                <Typography variant="subtitle1" sx={{fontSize:"12px"}}>{task.uuid}</Typography>
                            </Box>
                        </ListItem>
                    ))}
                </List>
            </Container>

            {/* Add NDA Dialog */}
            <Dialog open={openRemoveDialog} onClose={ addLoading ? null : ()=>setOpenRemoveDialog(false)}>
                <DialogTitle>Remove NDA</DialogTitle>

                {addLoading ? 
                    "Loading"
                :
                <>
                <DialogContent>
                Are you sure you want to remove the NDA from this project?
                    {addError && <Alert severity="error">{addError}</Alert>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setOpenRemoveDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleRemoveNdaTask} color="primary" disabled={addLoading}>
                        Remove
                    </Button>
                </DialogActions>
                </>}
            </Dialog>




            
            {/* Add NDA Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Add New NDA</DialogTitle>
                <DialogContent>

                <FormControlLabel control={<Switch onChange={(e)=>setNewNdaEnabled(e.target.checked)} checked={newNdaEnable} />} label="Make current NDA for project" />

                    
<ReactQuill 
        theme='snow'

      value={newNda}
      onChange={handleTextChange}
    />

                    
                    {addError && <Alert severity="error">{addError}</Alert>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddTask} color="primary" disabled={addLoading}>
                        {addLoading ? <CircularProgress size={24} /> : 'Add'}
                    </Button>
                </DialogActions>
            </Dialog>


            {/* View NDA Dialog */}
            <Dialog open={viewDialog} onClose={handleViewDialogClose}>
                <DialogTitle>View NDA</DialogTitle>
                <DialogContent>
                    
                    {viewLoading ? (
                        <CircularProgress />
                    ) : viewError ? (
                        <Typography color="error">{viewError}</Typography>
                    ) : (
                        selectedNDA && (
                            <Box>
                                {selectedNDA.project ? <Typography variant="h6">Used for projects</Typography> : null }
                                <Typography variant="h6">{selectedNDA.created}</Typography>
                                <Typography variant="subtitle1" sx={{fontSize:"12px"}}>{selectedNDA.uuid}</Typography>
                                {parse(selectedNDA.contains)}
                            </Box>
                        )
                    )}
                </DialogContent>
                <DialogActions>

                
                
                {!viewLoading ? (<>
                {selectedNDA && !selectedNDA.project ? (
                    <Button onClick={()=>handleEnable(selectedNDA)} color="primary">
                        Enable
                    </Button>
                )
                : null}
                
                <Button onClick={ ()=>handleEdit(selectedNDA)} color="primary">
                    Edit
                </Button></>
                ):null}
                
                <Button onClick={handleViewDialogClose} color="primary">
                    Close
                </Button>
                </DialogActions>
            </Dialog>
        </main>
    );
};

export default ProjectNDAs;
