import React, { useState, useEffect } from 'react';
import {
  Card,
  Divider,
  CardContent,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  ListItem,
  List,
  ListItemText,
  IconButton,
  Grid,
  Paper,
  Tabs,
  Tab,
} from '@mui/material';
import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import DescriptionIcon from '@mui/icons-material/Description';
import { getIdToken } from '../services/firebaseServices';

const Feed = ({ feedItems }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [allImages, setAllImages] = useState(null);
  const [allOtherFiles, setAllOtherFiles] = useState(null);
  const [imageURLs, setImageURLs] = useState({});
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const fetchImages = async () => {
      const token = await getIdToken();
      const urls = {};

      feedItems.forEach(item => {
        if (item.files) {
          item.files.forEach(file => {
            const fileURL = `https://api.blueprintportal.net/projects/${item.projectId}/files/${file.id}?Token=${token}`;
            urls[file.id] = fileURL;
          });
        }
      });

      setImageURLs(urls);
    };

    fetchImages();
  }, [feedItems]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleShowAllFiles = (images, otherFiles) => {
    setAllImages(images);
    setAllOtherFiles(otherFiles);
  };

  const handleCloseDialog = () => {
    setSelectedImage(null);
    setAllImages(null);
    setAllOtherFiles(null);
    setTabValue(0);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDownloadClick = (event, fileId) => {
    event.preventDefault(); // Prevent the Link's default behavior
    window.location.href = imageURLs[fileId]; // Trigger download
  };

  const renderFiles = (projectId, files) => {
    if (!files) return null;

    const images = files.filter((file) => file.content_type.startsWith('image/'));
    const otherFiles = files.filter((file) => !file.content_type.startsWith('image/'));

    return (
      <>
        {images.length > 0 && (
          <Grid container spacing={1} sx={{ my: 1 }}>
            {images.slice(0, 6).map((image, index) => (
              <Grid item xs={images.length === 1 ? 12 : images.length === 2 ? 6 : 2} key={index}>
                <Box
                  component="img"
                  loading="lazy"
                  src={images.length <3 ? imageURLs[image.id] : imageURLs[image.id]/*+"&size=small"*/}
                  alt={image.file_name}
                  sx={{ width: '100%', cursor: 'pointer' }}
                  onClick={() => handleImageClick(image)}
                />
              </Grid>
            ))}
            {images.length > 6 && (
              <Grid item xs={12}>
                <Button variant="contained" onClick={() => handleShowAllFiles(images, otherFiles)}>
                  +{images.length - 6} more
                </Button>
              </Grid>
            )}
          </Grid>
        )}
        {otherFiles.length > 0 && (
          <List>
            {otherFiles.slice(0, 3).map((file, index) => (
              <ListItem key={index}>
                <IconButton>
                  <DescriptionIcon />
                </IconButton>
                <Box sx={{ fontSize: "12px !important" }}>
                  <a
                    href={imageURLs[file.id]}
                    onClick={(event) => handleDownloadClick(event, file.id)}
                    download={file.file_name}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {file.file_name}
                  </a>
                </Box>
              </ListItem>
            ))}
          </List>
        )}
        {otherFiles.length > 3 && (
          <Grid item xs={12}>
            <Button variant="contained" onClick={() => handleShowAllFiles(images, otherFiles)}>
              +{otherFiles.length - 3} more
            </Button>
          </Grid>
        )}
      </>
    );
  };

  if (feedItems.length === 0) {
    return <Typography variant="subtitle1">No new updates.</Typography>;
  }

  return (
    <>
      {feedItems.map((item, index) => (
        <Box
          //onClick={()=>console.log(item.projectId)}
          key={index}
          sx={{ textDecoration: 'none' }}
          component={item.projectId || item.groupId ? Link : Box}
          to={item.projectId ? `/projects/${item.projectId}` : item.groupId ? `/groups/${item.groupId}` : null}
        >
          <Card sx={{ mt: 2, mb: 1 }}>
            <CardContent>
              <Typography variant="h6">{item.title}</Typography>
              <Typography variant="body2">{item.description}</Typography>
              <Divider sx={{ my: 1 }} />
              
            </CardContent>
              {item.files && renderFiles(item.projectId, item.files)}
              
            <CardContent>
              <Typography variant="body2" color="textSecondary">
                <TimeAgo date={item.updated} />
              </Typography>
            </CardContent>
          </Card>
        </Box>
      ))}

      <Dialog open={Boolean(selectedImage)} onClose={()=>setSelectedImage(null)}>
        {selectedImage && (
          <>
            <DialogTitle sx={{fontSize:"12px"}}>{selectedImage.file_name}</DialogTitle>
            <DialogContent>
              <Box component="img" 
                        loading="lazy" src={imageURLs[selectedImage.id]} alt={selectedImage.file_name} sx={{ width: '100%' }} />
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>setSelectedImage(null)} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <Dialog open={Boolean(allImages) || Boolean(allOtherFiles)} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        {(allImages || allOtherFiles) && (
          <>
            <DialogTitle>All Files</DialogTitle>

            {allImages.length>0 && allOtherFiles.length>0 ?
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="file type tabs">
                <Tab label="Images" />
                <Tab label="Other Files" />
              </Tabs>
              : null
            }


            <DialogContent>
              {((tabValue === 0 && allImages) || (allImages.length>0 && allOtherFiles.length==0) ) && (
                <Grid container spacing={2}>
                  {allImages.map((image, index) => (
                    <Grid item xs={3} sm={3} md={2} key={index}>
                      <Paper
                        component="img"
                  src={imageURLs[image.id]/*+"&size=small"*/}
                        alt={image.file_name}
                        loading="lazy"
                        sx={{ width: '100%', cursor: 'pointer' }}
                        onClick={() => handleImageClick(image)}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}


              { ((tabValue === 1 && allImages) || (allImages.length==0 && allOtherFiles.length>0)) && (
                <List>
                  {allOtherFiles.map((file, index) => (
                    <ListItem key={index}>
                      <IconButton>
                        <DescriptionIcon />
                      </IconButton>
                      <ListItemText>
                        <a
                          href={imageURLs[file.id]}
                          onClick={(event) => handleDownloadClick(event, file.id)}
                          download={file.file_name}
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {file.file_name}
                        </a>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default Feed;
