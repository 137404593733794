import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, TextField, Button,Select,MenuItem, Typography,InputLabel,FormControl, CircularProgress, Switch, FormControlLabel, FormGroup, Checkbox } from '@mui/material';
import { getIdToken } from '../../../../services/firebaseServices';
import ReactQuill from "react-quill"
import 'quill/dist/quill.snow.css'; // Add css for snow theme


const ProjectSettings = () => {
    const { projectId } = useParams();
    const [project, setProject] = useState({});
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [groupChecks, setGroupChecks] = useState({});


    
    const states = [
        'Idea', 'Planning', 'Prototyping', 'Seeking Funding', 'In Development', 'In Testing', 'Seeking Interest', 'Tooling', 'Ready for Sale', 'Launching', 'Completed', 'Paused', 'Canceled'
    ];


    useEffect(() => {
        const fetchData = async () => {
            try {
              
              const token = await getIdToken();
              const config = {
                  method: 'get',
                  url: `https://api.blueprintportal.net/projects/${projectId}`,
                  headers: { 'Authorization': `${token}` }
              };

              const projectRes = await axios(config);


                //const projectRes = await axios.get(`https://api.blueprintportal.net/projects/${projectId}`);
                //const groupsRes = await axios.get('https://api.blueprintportal.net/groups');
                setProject(projectRes.data);
                //setGroups(groupsRes.data.groups);
                let initialChecks = {};
                /*groupsRes.data.groups.forEach(group => {
                    initialChecks[group.id] = project.groups.includes(group.id);
                });*/
                setGroupChecks(initialChecks);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [projectId]);

    const handleInputChange = (event) => {
        const { name, value, checked, type } = event.target;
        setProject(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    
    const handledescriptionChange = (value, viewUpdate) => {
        //const { name, value, checked, type } = event.target;
        setProject(prev => ({
            ...prev,
            description:  value
        }));
    };


    

    const handleGroupChange = (groupId) => {
        setGroupChecks(prev => ({ ...prev, [groupId]: !prev[groupId] }));
    };

    const saveSettings = async () => {
        setLoading(true);
        try {

          const token = await getIdToken();
          const config = {
              method: 'put',
              url: `https://api.blueprintportal.net/projects/${projectId}`,
              headers: { 'Authorization': `${token}` },
              data: project
          };

          const response = await axios(config);

            alert('Settings updated successfully!');
            window.location.reload()
        } catch (error) {
            console.error('Failed to update settings:', error);
        }
        setLoading(false);
    };

    if (loading) return <CircularProgress />;

    return (
        <Box p={3}>
            <TextField
                fullWidth
                label="Project Title"
                margin="normal"
                name="title"
                value={project.title || ''}
                onChange={handleInputChange}
            />

            <FormControl fullWidth margin="normal">
                <InputLabel  id="state-select-label">State</InputLabel>
                <Select
                    labelId="state-select-label"

                    label="State"
                    name="state"
                    value={project.state || 'Idea'}
                    onChange={handleInputChange}
                >
                    {states.map(state => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>


{/*}
            <TextField
                fullWidth
                label="Project Description"
                margin="normal"
                name="description"
                multiline
                rows={4}
                value={project.description || ''}
                onChange={handleInputChange}
            />*/}

<ReactQuill 
        theme='snow'

      value={project.description || ''}
      onChange={handledescriptionChange}
      style={{minHeight: '300px'}}
      height="200px"
    />



            <FormControlLabel
                control={<Switch checked={project.private || false} onChange={handleInputChange} name="private" />}
                label="Private"
            />
            <br/>
            {/*<FormControlLabel
                control={<Switch checked={project.ndaRequired || false} onChange={handleInputChange} name="ndaRequired" />}
                label="NDA Required"
            />
            {project.ndaRequired && (
                <Box mt={2}>
                    <Typography variant="h6">NDA Details</Typography>
                    <TextField
                        fullWidth
                        label="NDA Document Link"
                        margin="normal"
                        name="ndaLink"
                        value={project.ndaLink || ''}
                        onChange={handleInputChange}
                    />
                </Box>
            )}*/}
            <Box mt={2}>
                <Typography variant="h6">Associate Groups</Typography>
                {groups.map(group => (
                    <FormControlLabel
                        key={group.id}
                        control={<Checkbox checked={groupChecks[group.id]} onChange={() => handleGroupChange(group.id)} />}
                        label={group.name}
                    />
                ))}
            </Box>
            <Button variant="contained" color="primary" onClick={saveSettings} sx={{ mt: 2 }}>
                Save Settings
            </Button>
        </Box>
    );
};

export default ProjectSettings;
