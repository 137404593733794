import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getIdToken } from '../../services/firebaseServices'; // Adjust the path to where firebaseServices is defined
import { List, ListItem,Box, ListItemText, Typography, Container, CircularProgress, Paper } from '@mui/material';
import {Link } from 'react-router-dom';
import TimeAgo from 'react-timeago'


const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const token = await getIdToken();
                const config = {
                    method: 'get',
                    url: 'https://api.blueprintportal.net/notifications',
                    headers: { 'Authorization': `${token}` }
                };

                const response = await axios(config);
                setNotifications(response.data || []);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching notifications:', err);
                setError('Failed to load notifications');
                setLoading(false);
            }
        };

        fetchNotifications();
    }, []);

    if (loading) {
        return (
            <Container>
                <CircularProgress />
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <Typography color="error">{error}</Typography>
            </Container>
        );
    }

    return (
        <Container component="main" maxWidth="md">
            <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
                <Typography variant="h5" gutterBottom>
                    Notifications
                </Typography>
                <List>
                    {notifications.length > 0 ? (
                        notifications.map((notification, index) => (
                            <ListItem 
                                component={notification.projectDetails || notification.groupDetails ? Link:ListItem} 
                                to={notification.projectDetails ? `/projects/${notification.projectDetails.id}` : notification.groupDetails ? `/groups/${notification.groupDetails.id}` :null} 

                                key={index}

                                divider
                                sx={!notification.read ? {borderLeft: "rgb(253, 83, 97) solid 6px;"} : {}}
                            >
                                
                                <Box sx={{color:"rgba(0, 0, 0, 0.87)"}}>
                                    
                                    {
                                        notification.projectDetails && notification.projectDetails.id ?
                                            <Box><strong>Project:</strong> {notification.projectDetails.title}</Box>
                                        :
                                        notification.groupDetails && notification.groupDetails.id ?
                                            <Box><strong>Group: {notification.groupDetails.name}</strong></Box>
                                        :
                                        <Box><strong>Genral</strong></Box>

                                    }

                                    <Box>{notification.content}</Box>
                                    <Box sx={{fontSize:"11px", mt:"6px"}}><TimeAgo date={notification.createdAt} /></Box>

                                    

                                </Box>
                 
                            </ListItem>
                        ))
                    ) : (
                        <Typography variant="body1">No new notifications.</Typography>
                    )}
                </List>
            </Paper>
        </Container>
    );
};

export default Notifications;
