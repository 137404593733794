import React, { useState } from 'react';
import { Container, Typography, Paper, TextField, Button, Grid, Box } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import axios from 'axios';
import { getIdToken } from '../services/firebaseServices';  // Adjust the path to where firebaseServices is defined

const Contact = () => {
    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (event) => {
        
      event.preventDefault();
      setLoading(true);
      try {
          const token = await getIdToken();  // Get the ID token
          const config = {
              method: 'post',
              data:JSON.stringify({name:formData.name,email:formData.email,message:formData.message}),
              url: 'https://api.blueprintportal.net/contact',
              headers: { 'Authorization': token,'Content-Type': 'application/json'}
          };

          const response = await axios.request(config);
          
        alert('Thank you for contacting us!');
        console.log(formData);
        // Reset form data
        setFormData({ name: '', email: '', message: '' });


/*
          if(response.data && response.data.project)
            navigate(`/projects/${response.data.project.id}`);*/
          setLoading(false);

          
              // Redirect to the projects list view after adding

      } catch (err) {
          console.error('Error fetching projects:', err);
          setError('Failed to load projects');
          setLoading(false);
      }




/*

        event.preventDefault();
        // Implement what happens when form is submitted, e.g., sending an email
        alert('Thank you for contacting us!');
        console.log(formData);
        // Reset form data
        setFormData({ name: '', email: '', message: '' });*/
    };

    return (
        <Container component="main" maxWidth="md">
            <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Contact Us
                </Typography>
                <Typography paragraph>
                    If you have any questions, please feel free to reach out to us. We are here to help!
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        required
                        label="Your Name"
                        name="name"
                        fullWidth
                        variant="outlined"
                        value={formData.name}
                        onChange={handleChange}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        required
                        label="Your Email"
                        name="email"
                        fullWidth
                        variant="outlined"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        required
                        label="Your Message"
                        name="message"
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4}
                        value={formData.message}
                        onChange={handleChange}
                        sx={{ mb: 2 }}
                    />
                    <Button type="submit" variant="contained" color="primary">
                        Send Message
                    </Button>
                </form>
                
            </Paper>
        </Container>
    );
};

export default Contact;
